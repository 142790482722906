<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M503.172,176.552H8.828c-4.875,0-8.828,3.953-8.828,8.828v44.138c0,4.875,3.953,8.828,8.828,8.828
	h9.416l15.418,231.278c0.928,13.912,12.482,24.722,26.424,24.722h391.828c13.942,0,25.496-10.809,26.424-24.722l15.419-231.278
	h9.416c4.875,0,8.828-3.953,8.828-8.828v-44.138C512,180.504,508.047,176.552,503.172,176.552z M132.414,432.552
	c0,9.75-7.905,17.655-17.655,17.655s-17.655-7.905-17.655-17.655V300.138c0-9.751,7.905-17.655,17.655-17.655
	s17.655,7.904,17.655,17.655V432.552z M203.034,432.552c0,9.75-7.905,17.655-17.655,17.655c-9.75,0-17.655-7.905-17.655-17.655
	V300.138c0-9.751,7.905-17.655,17.655-17.655c9.75,0,17.655,7.904,17.655,17.655V432.552z M273.655,432.552
	c0,9.75-7.905,17.655-17.655,17.655s-17.655-7.905-17.655-17.655V300.138c0-9.751,7.905-17.655,17.655-17.655
	s17.655,7.904,17.655,17.655V432.552z M344.276,432.552c0,9.75-7.905,17.655-17.655,17.655s-17.655-7.905-17.655-17.655V300.138
	c0-9.751,7.905-17.655,17.655-17.655s17.655,7.904,17.655,17.655V432.552z M414.897,432.552c0,9.75-7.905,17.655-17.655,17.655
	c-9.75,0-17.655-7.905-17.655-17.655V300.138c0-9.751,7.905-17.655,17.655-17.655c9.75,0,17.655,7.904,17.655,17.655V432.552z"
    />
    <g>
      <path
        style="fill:#FFC850;"
        d="M18.243,238.345l15.418,231.278c0.928,13.912,12.482,24.722,26.424,24.722H86.51L71.876,274.829
		C71.197,264.639,79.279,256,89.492,256h403.087l1.177-17.655H18.243z"
      />
      <path
        style="fill:#FFC850;"
        d="M326.621,185.379c0,19.501,15.809,35.31,35.31,35.31s35.31-15.809,35.31-35.31
		c0-3.066-0.513-5.991-1.248-8.828H327.87C327.134,179.389,326.621,182.313,326.621,185.379z"
      />
      <path
        style="fill:#FFC850;"
        d="M114.759,185.379c0,19.501,15.809,35.31,35.31,35.31s35.31-15.809,35.31-35.31
		c0-3.066-0.513-5.991-1.248-8.828h-68.124C115.272,179.389,114.759,182.313,114.759,185.379z"
      />
    </g>
    <g>
      <path
        style="fill:#707487;"
        d="M141.238,203.501L141.238,203.501c8.445,4.875,19.242,1.982,24.117-6.462l88.276-152.898
		c4.875-8.445,1.982-19.242-6.462-24.117l0,0c-8.445-4.875-19.242-1.982-24.117,6.462l-88.276,152.898
		C129.901,187.828,132.794,198.626,141.238,203.501z"
      />
      <path
        style="fill:#707487;"
        d="M370.762,203.501L370.762,203.501c-8.445,4.875-19.242,1.982-24.117-6.462L258.369,44.141
		c-4.875-8.445-1.982-19.242,6.462-24.117l0,0c8.445-4.875,19.242-1.982,24.117,6.462l88.276,152.898
		C382.099,187.828,379.206,198.626,370.762,203.501z"
      />
    </g>
    <g>
      <path
        style="fill:#5B5D6E;"
        d="M247.169,20.023c-8.444-4.875-19.242-1.982-24.117,6.462l-88.276,152.898
		c-4.875,8.445-1.982,19.242,6.462,24.117l0,0L247.169,20.023L247.169,20.023z"
      />
      <path
        style="fill:#5B5D6E;"
        d="M264.831,20.023L264.831,20.023c-8.445,4.875-11.338,15.673-6.462,24.117l88.276,152.898
		c4.875,8.445,15.673,11.338,24.117,6.462l0,0L264.831,20.023z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
